@import "../../styles/fira-theme.scss";

.TableWrapper {
  width: 100%;
  height: 100%;
  padding-left: 9px;
  padding-right: 10px;
  box-sizing: border-box;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
  &.TableTransparent {
    background-color: transparent;
    border-top: none;
  }
  &.NoHeader {
    border-top: none;
    border-radius: 8px;
  }

  > table.Draggable {
    tr {
      cursor: grab;
    }
  }

  > table.Table {
    width: 100%;
    border-collapse: inherit;
    border-spacing: 0px 10px;
    > thead {
      width: 100%;
      position: sticky;
      top: 0;
      background-color: $light-background;
      z-index: 2;
      &.TableTransparent {
        background-color: transparent;
      }
      .Row {
        box-sizing: border-box;
      }
    }
    .Cell,
    .Head {
      text-align: start;
      box-sizing: border-box;

      &.CenterContent {
        text-align: center;
      }
      &.RightContent {
        text-align: -webkit-right;
        text-align: -moz-webkit-right;
      }
      &.background {
        background-color: $main-background;
      }
      &.flexRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    > tbody {
      width: 100%;
      max-height: 640px;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center;

      > tr.Row {
        height: 60px;
        background-color: $light-background;
        &.onClick {
          cursor: pointer;
        }
        &:hover {
          > td.Cell {
            border-top: 1.5px solid $primary-color;
            border-bottom: 1.5px solid $primary-color;
            &:first-child {
              border-left: 1.5px solid $primary-color;
            }
            &:last-child {
              > * {
                display: flex;
              }
              border-right: 1.5px solid $primary-color;
            }
          }
        }
        &.iluminated {
          > td.Cell {
            border-top: 1.5px solid $primary-color;
            border-bottom: 1.5px solid $primary-color;
            &:first-child {
              border-left: 1.5px solid $primary-color;
            }
            &:last-child {
              > * {
                display: flex;
              }
              border-right: 1.5px solid $primary-color;
            }
          }
        }
        &.inactive {
          background-color: $main-background;
        }
        &.shrink {
          height: 53px;
        }

        > td.Cell {
          height: 60px;
          border-top: 0.5px solid $dark-gray-color;
          border-bottom: 0.5px solid $dark-gray-color;
          transition: 0.1s ease-in;
          box-sizing: border-box;
          .LimitText {
            max-width: 170px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &.shrink {
            height: 100%;
          }
          &:first-child {
            border-left: 0.5px solid $dark-gray-color;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            > * {
              display: none;
            }
            padding: 0px;
            width: 1%;
            white-space: nowrap;
            border-right: 0.5px solid $dark-gray-color;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 0px;
          }
        }
        > td.CellNoBorder {
          height: 60px;

          border-bottom: 0.5px solid $dark-gray-color;
          transition: 0.1s ease-in;
          box-sizing: border-box;
          .LimitText {
            max-width: 170px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &.shrink {
            height: 100%;
          }
        }
      }
    }
  }

  &.Underline {
    border: 0;

    .Table {
      border-spacing: 0px;
      > thead {
        height: 32px;
        th {
          height: 32px;
        }
      }
      > tbody {
        pointer-events: none;
        > tr.Row {
          &:first-child {
            .Cell {
              border-top: 0;
            }
          }
          > td.Cell {
            border-left: 0;
            border-right: 0;
            border-top: 1px solid #d5dde0;
            border-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            height: 48px !important;
            &:first-child {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border: 0;
            }
            &:last-child {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border: 0;
            }
          }
        }
      }
    }
  }
}

.TableHeader {
  box-sizing: border-box;
  width: 100%;
  height: 66px;
  padding: 10px 15px 12px 16px;
  background-color: $light-background;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // grid-template-columns: 0fr 1fr;
  // &.nocheckBox {
  //   grid-template-columns: 1fr;
  // }
  &.Separated {
    margin-bottom: 9px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .CheckboxContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 17px;
    margin-left: 12px;
    margin-right: 23px;
    font-size: 14px;
    .SelectedText {
      white-space: nowrap;
    }
  }
  .SearchIcon {
    font-size: 30px;
  }
  &.IsActive {
    .CheckboxContainer {
      width: auto;
      border: 1px solid $medium-gray-color;
      border-radius: 6px;
      padding-left: 9px;
      margin-left: 0;
      margin-right: 10px;
      :first-child {
        margin: 0;
      }
      .SelectedText {
        margin-left: 11px;
        margin-right: 9px;
      }
      .Options {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-left: 1px solid $medium-gray-color;
        padding-left: 3.96px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        &.Inactive {
          color: $dark-gray-color;
        }
        > .Icon {
          font-size: 30px;
        }
      }
    }
  }
  .orderingContainer {
    display: flex;
    flex-direction: row;
    border: 1px solid $medium-gray-color;
    border-radius: 6px;
    margin-left: 10px;
    padding: 12px 16px;
    align-items: center;
    position: relative;
    .orderingTitle {
      width: 100px;
      height: 24px;
      display: flex;
      gap: 21px;
      cursor: pointer;
      align-items: center;
    }

    .orderingOptions {
      background-color: $light-background;
      box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
      position: absolute;
      width: calc(100% - 16px);
      z-index: 20;
      padding: 8px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      gap: 8px;
      display: flex;
      flex-direction: column;
      padding-top: 16px;
      top: 38px;
      left: -1px;
      border: 1px solid $medium-gray-color;
      .insideOption {
        border: 1px solid $medium-gray-color;
        border-radius: 6px;
        padding: 4px 12.5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

.TableImage {
  height: 50px;
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  background-color: $dark-gray-color;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  > img {
    height: 50px;
    width: 50px;
  }
}
.TableButton {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.SearchContainer {
  width: 100%;
}

.invertedHeader {
  flex-direction: row-reverse;
  .CheckboxContainer {
    margin-left: 23px;
    margin-right: 17px;
  }
}

@supports (object-fit: cover) {
  .TableImage img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}
