@import "./../../styles/fira-theme.scss";

.Input {
  cursor: pointer;
  width: 100%;
  background-color: #f7f8f9;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 2px 38px 2px 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  gap: 10px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  img {
    width: 24px;
  }
}

.FiraSelect {
  width: 100%;
  overflow-x: hidden;
  background-color: #f7f8f9;
  border: 1px solid $medium-gray-color;
  border-radius: 0px 0px 8px 8px;
  max-height: 230px;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;

  .options {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    div {
      background-color: #f7f8f9;
      cursor: pointer;
      box-sizing: border-box;
      padding: 10px 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      img {
        width: 24px;
      }
    }

    div:hover {
      background-color: #efefef;
    }
  }

  .options::-webkit-scrollbar {
    width: 8px;
  }

  .options::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &__Placeholder {
    color: #abafb1;
  }
}

.FiraSelect.hasImage {
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
  }
}

.Profile {
  background-color: #f7f8f9;
}

.Outline,
.Phonecode {
  background-color: #fff;
  border: 1px solid #d5dde0;
  border-radius: 4px;
}

.OptionsOpen {
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}

.ArrowIcon {
  font-size: 10px;
  position: absolute;
  right: 17px;
  //height: 100%;
}

.Disabled {
  pointer-events: none;
  background-color: $main-background;
  color: #abafb1;
}
