@import "../../styles/fira-theme.scss";

.popupContainer {
  width: 178px;
  max-width: 229px;
  height: auto;
  border-radius: 10px;
  background-color: $light-background;

  display: flex;
  flex-direction: column;
  justify-content: center;
  // border: 1px solid #d0d0d0;
  box-sizing: border-box;
  box-shadow: 0px 25px 35px rgba(10, 8, 36, 0.1);
  border-radius: 10px;
  padding: 13px;
  left: 65px;

  .popupText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
  }
}

.triangle {
  width: 9px;
  height: 11px;
  background-color: #ffffff;
  transform: rotate(156deg) skewX(44deg) scale(1, -0.6);
  z-index: 0;
  position: absolute;
  top: -7.2px;
  left: 17px;
  border-top: solid 2px #d0d0d0;
  border-left: solid 2px #d0d0d0;
  border-top-left-radius: 2px;
}
