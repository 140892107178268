.AlertWrapper {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 600px;
  box-shadow: 0px 16px 40px rgba(3, 2, 41, 0.07);
  border-radius: 8px;
  padding: 20px;
  opacity: 0;
  animation: fade-in 0.5s ease-in-out forwards;
  border: 1px solid;

  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr 24px;
  gap: 16px;

  z-index: 5000;

  &.Success {
    background-color: #66cb9f;
    border-color: #66cb9f;
    color: #fff;
    .AlertCloseButton,
    .AlertIconWrapper {
      color: #fff;
    }
  }

  &.Warning {
    background-color: #ffffff;
    border-color: #fdfd55;
    color: #000;
    .AlertCloseButton,
    .AlertIconWrapper {
      color: #000;
    }
  }

  &.Error {
    background-color: #f16063;
    border-color: #cc5f5f;
    color: #fff;
    .AlertCloseButton,
    .AlertIconWrapper {
      color: #fff;
    }
  }

  > .AlertIconWrapper,
  > .AlertCloseButton {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }

  > .AlertMessageWrapper {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  > .AlertCloseButton {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
