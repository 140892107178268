@import "../../styles/fira-theme.scss";

.ModalWrapper {
  z-index: 10001;
  background-color: #0102024d;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 218px;
  padding-top: 56px;
  transition: 0.1s ease-in;
  flex-direction: column;
  &.IsClosed {
    padding-left: 0px;
  }
}

.rightAlign {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-start;
  padding-top: 40px;
}

@media screen and (max-width: 1200px) {
  .ModalWrapper {
    padding-top: 56px;
  }
}
