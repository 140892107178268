@import "../../../styles/fira-theme.scss";

.accountOwnerDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .countersWrapper {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    &.mobileCounter {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  .desktopContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 24.3%;
    grid-template-rows: 1fr;
    gap: 10px;
    &__firstCol,
    &__secondCol {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__firstCol {
      display: grid;
      grid-template-rows: 380px 340px;
    }
  }

  .mobileContainer {
    display: grid;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    .subGrid {
      max-height: 475px;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "views interactions"
        "list interactions"
        "list interactions"
        "list interactions";
      &__list {
        grid-area: list;
        max-height: 340px;
      }
      &__views {
        grid-area: views;
      }
      &__interactions {
        grid-area: interactions;
      }
    }
  }

  .VerticalContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .SwitchContainer {
    .TitleWrapper > span {
      font-size: 16px;
      font-weight: 700;
    }

    .TitleWrapper, .SubTitleWrapper {
      padding: 10px 16px;
      &.Disabled {
        color: #d0d0d0;
      }
    }

    .SubTitleWrapper > span {
      font-size: 14px;
      
    }

    .ContentWrapper {
      padding: 0px 16px;

      &.FlexWrapper {
        display: flex;
        justify-content: space-between;
      }
    }
     
    .ContentWrapper:last-child {
      padding-bottom: 16px;
    }
    background: #ffffff;
    border-radius: 15px;
  }

  .tableContainer {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .titleSection {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titleHeaderTable {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .leftContainer {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 8px;
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    justify-content: space-between;
    /* Blanco */
    background: #ffffff;
    border-radius: 15px;
    padding: 24px;
    box-sizing: border-box;

    .cardContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .infoTextTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .infoText {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .rowContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8px;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .rowContainerGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .topContainer {
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bottomContainer {
    height: 30%;
    display: flex;
    align-items: end;
    width: 100%;
  }

  .rightContainerMobile {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr;
    gap: 30px;

    width: 100%;
    margin-top: 8px;
    /* Blanco */
    background: #ffffff;
    border-radius: 15px;
    padding: 24px;
    box-sizing: border-box;

    .cardContainerBottom {
      grid-column: 1 / span 3;
      gap: 8px;
    }
    .itemRow {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 10px;
      justify-content: space-between;
    }
    .cardContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .infoTextTitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .infoText {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .rowContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .btnTransfer {
    border-radius: 4px;
    border: 1px solid var(--amarillo, #ffde07);
  }

  .buttonTd {
    display: flex;
    justify-content: end;
  }
  .labelDetail {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .labelItem {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.emptyInfiniteTableWrapper {
  width: 100%;
  min-height: 300px;

  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infiniteTableWrapper {
  width: 100%;
  display: grid;
  overflow: auto;
}
.tableImg {
  display: flex;
  background-color: #d0d0d0;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  img {
    height: 34px;
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
  }
}

.centertd {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textid {
  text-overflow: ellipsis;
  width: 151px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.editlink {
  color: black;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.textButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.textButtonRed {
  color: #f16063;
}
.itemStatus {
  display: flex;

  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;

  &.active {
    border-radius: 4px;
    background: #fff29c;
  }
  &.inactive {
    border-radius: 4px;
    background: #f7f8f9;
  }
}

.fakeTable {
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  .fakeRow {
    border-top: 0.5px solid $dark-gray-color;
    border-bottom: 0.5px solid $dark-gray-color;
    box-sizing: border-box;

    .fakeCell {
      border-top: 0.5px solid #d0d0d0;
      border-bottom: 0.5px solid #d0d0d0;
      transition: 0.1s ease-in;
      box-sizing: border-box;
      &.first {
        border-left: 0.5px solid #d0d0d0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &.last {
        border-right: 0.5px solid #d0d0d0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .centered {
        display: flex;
        justify-content: center;
      }
    }
  }
}
