@import "../../styles/fira-theme.scss";

.emptyInfiniteTableWrapper {
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infiniteTableWrapper {
  height: 75vh;
  margin-bottom: 50px;
}
.labelDetail {
  text-decoration: underline;
  cursor: pointer;
}

.LinkToNewUser {
  color: $main-text;
  text-decoration: none;
}

.buttonTd {
  display: flex;
  justify-content: end;
}
