@import "../../styles/fira-theme.scss";
.FiraInputSearchWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: relative;
  &.isOpen {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .inputPlaceholder {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    line-height: 40px;
    padding: 0 10px;
    color: #abafb1;
  }
  .FiraInputSearch {
    width: 100%;
    height: 100%;

    background-color: $light-background;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    > input {
      background-color: transparent;
      height: 100%;
      width: 100%;
      padding: 8px 12px;
      padding-right: 140px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid var(--borde, #d5dde0);
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
    }
    > input::placeholder {
      font-size: 16px;
      color: #abafb1;
      font-family: "Montserrat", sans-serif;
    }
    > input:focus {
      border: 1px solid $primary-color;
      outline: none;
    }
    &.isOpen {
      border-radius: 4px 4px 0 0;
    }
  }
  .FiraInputSearchOptions {
    top: 40px;
    position: absolute;
    width: 100%;
    background-color: #f7f8f9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    z-index: 2;
    &__Item {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      padding: 0 8px 0 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &__Item:hover {
      background-color: $dark-gray-color;
    }

    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
}

.errorMessage {
  color: #f16063;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  margin-top: 4px;
}
