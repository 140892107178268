@import "../../../styles/fira-theme.scss";

.HeaderDashboard {
  min-height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ItemText {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
    }
    .info {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.OptionsBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .countersWrapper {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    &.mobileCounter {
      grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: repeat(2, 1fr);
    }
  }
  .dataContainer {
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    gap: 15px;
    display: flex;
    flex-direction: column;

    .subheader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .itemLeft {
      display: flex;
      flex-direction: column;

      .title {
        color: #212121;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 800;
      }
      .subtitle {
        color: #212121;
        font-size: 13px;
        font-family: Montserrat;
      }
    }

    .itemRight {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
    }
  }
  .desktopContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 388px;
    grid-template-rows: 1fr;
    gap: 16px;
    &__firstCol,
    &__secondCol {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__firstCol {
      display: grid;
      grid-template-rows: 380px 340px;
    }
  }

  .mobileContainer {
    display: grid;
    max-width: 100%;
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
    .subGrid {
      max-height: 475px;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "views interactions"
        "list interactions"
        "list interactions"
        "list interactions";
      &__list {
        grid-area: list;
        max-height: 340px;
      }
      &__views {
        grid-area: views;
      }
      &__interactions {
        grid-area: interactions;
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(346px, 1fr));
    gap: 9px;
    height: 100%;
    box-sizing: border-box;
    .leftContainers {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 100%;
      .table {
        display: flex;
        background-color: $light-background;
        height: 100%;
        width: 100%;
        padding: 12px 18px;
        border-radius: 10px;
        box-sizing: border-box;
        .tableHeader {
          padding: 0px 9px;

          .tableSubtitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
          }
        }
        .tableContainer {
          height: 100%;
        }
      }
      .graph {
        display: flex;
        flex-direction: column;
        background-color: $light-background;
        height: 276px;
        width: 652px;
        border-radius: 10px;
        gap: 30px;
        .grapHeader {
          display: flex;
          place-content: space-between;
          padding: 10px 17px 0px 24px;
          .selectGroup {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .rightContainers {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .events {
        background-color: $light-background;
        display: flex;
        flex-direction: column;
        height: 300px;
        width: 100%;
        border-radius: 10px;
        padding: 16px 24px 17px 24px;
        box-sizing: border-box;
        gap: 18px;
        .textNoTitle {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90%;
          flex-direction: column;
        }
        .eventsElementsWrapper {
          height: 90%;
        }
        .textLink {
          color: $primary-color;
          text-align: center;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
        }
        .nextEventsContainer {
          height: 100%;
        }
        .buttonNextEvents {
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-top: 9px;
          padding: 0px;
        }
      }
      .activity {
        background-color: $light-background;
        display: flex;
        height: 317px;
        width: 226px;
        border-radius: 10px;
      }
    }
  }
}

.skeletonCard {
  width: 120px;
  height: 118px;
  border-radius: 10px;
  background-color: $light-background;
  place-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  margin: 3px;
}
.skeletonCell {
  gap: 10px;
  margin: 3px;
}

.EventName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.SearchContainer {
  display: flex;
  position: relative;

  .optionslist {
    position: absolute;
    top: calc(45%); /* Ajusta la posición vertical según tus necesidades */
    z-index: 100;
    width: 94%;
    background-color: #f7f8f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 5px;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
  }
}

.SearchContainer .optionslist li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  padding: 5px 0;
  cursor: pointer;
}
.SearchContainer .optionslist li.selected {
  background-color: #e0e0e0;
}
.SearchContainer .optionslist li:last-child {
  border-bottom: none;
}
