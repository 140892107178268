@import "../../styles/fira-theme.scss";

.LoaderWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0102024d;
  z-index: 10000;
}
