@import "./../../styles/fira-theme.scss";

.Logo {
  font-size: 68px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.SubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.ErrorText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: justify;
  /* Rojo */
  color: #f16063;
}
