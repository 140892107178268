@import "../../../styles/fira-theme.scss";

.OperStoreContainer {
  width: 849px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0 30px 0;
  height: 400px;

  .headerModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;
    margin-bottom: 7px;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.tableImg {
  display: flex;
  background-color: #d0d0d0;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  img {
    height: 34px;
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
  }
}
.infiniteTableWrapper {
  width: 98%;
  min-height: 334px;
}
.emptyInfiniteTableWrapper {
  width: 98%;
  min-height: 334px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemStatus {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  margin-left: 16px;
  margin-right: 16px;

  &.active {
    border-radius: 4px;
    background: #fff29c;
  }
  &.inactive {
    border-radius: 4px;
    background: #f7f8f9;
  }
}

.labelItem {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.titleHeaderTable {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
