@import "../../../styles/fira-theme.scss";

.EditStoreContainer {
  width: 620px;
  box-sizing: content-box;
  background-color: $light-background;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;

  .itemRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid var(--Borde, #d5dde0);
    max-height: 140px;
    .containerItem {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .textDescription {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;
  }

  .textTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  .headerContainer {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .headerTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .headerSubtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 8px;
  }
}

@media screen and (max-width: 800px) {
  .EditStoreContainer {
    width: 98%;
  }
}
