@import "./../../styles/fira-theme.scss";

.Header {
  top: 0;

  width: 100%;

  display: grid;
  grid-template-areas: "leadingSection trailingSection";
  position: relative;
}
.btnBack {
  cursor: pointer;
}
.LeadingSection {
  grid-area: leadingSection;
  min-height: 70px;
  align-items: center;
  justify-items: center;
  display: flex;
  grid-template-columns: auto;
  gap: 25px;
  > .DefaultText {
    font-weight: 600;
    font-size: 20px;
  }
}
.LeadingSectionOptions {
  display: grid;
  grid-template-columns: auto;
}
.LimitText {
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.TrailingSectionContainer {
  display: flex;
}
.TrailingSection {
  display: grid;
  grid-area: trailingSection;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  position: relative;
  z-index: 100;
  justify-content: end;

  grid-row-gap: 5px;
}

.TrailingSection button:active {
  transform: translateY(1px);
}

.MenuOption {
  display: none;
  grid-area: menu;
  margin: 0 40px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}
.MenuOption:active {
  transform: scale(1.1);
}

.buttonMenu {
  width: 36px;
  height: 36px;
  display: flex;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid #d5dde0;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 700px) {
  .Header {
    grid-template-areas: "leadingSection menu" "0 trailingSection";
    width: 100%;
  }
  .LeadingSection {
    height: auto;
  }
  .TrailingSection {
    grid-template-rows: repeat(4, auxto);
    grid-template-columns: none;

    padding: 10px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: center;
    position: absolute;
    top: 8%;
    right: 2%;
  }
  .MenuOption {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
  }
  .LimitText {
    max-width: 300px;
  }
  .LeadingSectionOptions {
    display: none;
  }
}

@media (max-width: 500px) {
  .Header {
    grid-template-areas: "leadingSection menu" "trailingSection trailingSection";
    width: 95%;
  }
  .MenuOption {
    margin: 0 20px 0 0;
  }
}
