@import "../../styles/fira-theme.scss";

.CustomSelect {
  display: flex;
  flex-direction: column;
  position: relative;

  .select {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0px 6px;
    text-overflow: ellipsis;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &.border {
      border: 1px solid #d5dde0;
    }
    &.clicked {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .innerElement {
      display: flex;
      flex-direction: row;
      height: 14px;
      align-items: center;
      width: 100%;
      &.center {
        justify-content: center;
      }
      .indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .text {
        margin-left: 5px;
      }
    }
  }
  .list {
    box-shadow: 0px 15px 40px 0px #3049bf0d;
    position: absolute;
    width: calc(100% - 16px);
    z-index: 1;
    padding: 8px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    top: 29px;
    max-height: 200px;
    overflow-y: auto;
    &.borderList {
      border: 1px solid #d5dde0;
      width: calc(100% - 18px);
    }

    .element {
      border-radius: 6px;
      height: 34px;
      display: flex;
      justify-content: left;
      cursor: pointer;
      font-size: 12px;

      align-items: center;
      margin-bottom: 5px;
      &.borderElement {
        border: 1px solid #d5dde0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &.LightBackground {
    .select {
      background-color: $light-background;
    }
    .list {
      background-color: $light-background;
      .element {
        &:hover {
          background-color: $main-background;
        }
      }
    }
  }
  &.MainBackground {
    .select {
      background-color: $main-background;
    }
    .list {
      background-color: $main-background;
      .element {
        &:hover {
          background-color: $light-background;
        }
      }
    }
  }
}
