.ViewLayoutWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 auto;
  width: calc(100% - 240px);
  max-width: 1400px;
  height: 100%;
  box-sizing: border-box;

  padding-left: 21px;
  padding-right: 21px;
  margin-bottom: 16px;
  overflow-y: auto;
}

@media screen and (max-width: 1200px) {
  .ViewLayoutWrapper {
    width: 100%;
  }
}
