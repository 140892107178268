@import "../../styles/fira-theme.scss";

.Button {
  font-family: inherit;
  padding: 3px 9px;
  white-space: nowrap;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.Main {
    background-color: $primary-color;
  }
  &.Border {
    background-color: transparent;
    border: 1px solid $medium-gray-color;
  }
  &.Transparent {
    background-color: transparent;
  }
  &:disabled {
    background-color: $dark-gray-color;
    opacity: 1;
    cursor: not-allowed;
  }
  &.Inactivate {
    background-color: $dark-gray-color;
  }
  &.Load {
    background-color: $light-background;
    border: 1px solid black;
  }
  &.Light {
    background-color: $light-background;
    border: 1px solid $medium-gray-color;
  }
  &.Danger {
    color: $light-text;
    background-color: $danger-color;
  }
  &.Delete {
    border: 1px solid $danger-color;
    color: $danger-color;
    background-color: transparent;
  }
  &.BorderYellow {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--amarillo, #ffde07);
  }
  &.BorderRed {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--rojo, #f16063);
  }
}
