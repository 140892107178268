.ContainerCardWidget {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  gap: 7px;
  width: 100%;

  min-height: 113px;

  /* Blanco */
  background: #ffffff;
  border-radius: 15px;
  padding: 21px 0px;
  box-sizing: border-box;

  .TitleCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    /* identical to box height */
    text-align: center;

    /* Negro */
    color: #000000;
  }

  .DataCard {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
  }

  .infoTooltip {
    position: absolute;
    display: flex;
    top: 6px;
    right: 6px;
    height: 20px;
    &:hover {
      color: "#ffde07";
    }
  }
}
