@import "../../styles/fira-theme.scss";

.InputWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .passwordButton {
    color: $dark-gray-color;
    display: flex;
    font-size: 30px;
    position: absolute;
    right: 6px;
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }

  .icon {
    font-size: 15px;
    position: absolute;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    color: $dark-gray-color;

    &.left {
      left: 6px;
    }

    &.right {
      right: 6px;
    }

    .check {
      color: $success-color;
    }

    .error {
      color: $danger-color;
    }
  }

  .Input {
    background-color: $light-background;
    left: 77px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid $medium-gray-color;
    padding-left: 6px;
    padding-right: 6px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &.Invalid {
      border: 1px solid $danger-color;
    }

    &.Error {
      padding-right: 22px;
    }

    &.Check {
      padding-right: 22px;
    }

    &.left {
      padding-left: 42px;
    }

    &.right {
      padding-right: 42px;
    }

    &.AuthStyleInput {
      border: 1px solid #d5dde0;
      box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
        0px 0px 1px rgba(50, 50, 71, 0.2);
    }
    &.URL {
      background: #ffffff;

      /* Borde */
      border: 1px solid #d5dde0;
      border-radius: 10px;
    }
    &.Configuration {
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: #f7f8f9;
      padding: 16px;
      font-size: 16px;
      font-weight: 400;
    }
    &.Profile {
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
    }

    &:focus {
      border: 1px solid $primary-color;
      outline: none;

      + .left {
        color: $primary-color;
      }
    }

    &:disabled {
      background-color: $main-background;
      color: #abafb1;
    }
    &.center-text {
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
    }

    &.placeholderRight::placeholder {
      text-align: right;
    }
  }

  .progress-circle {
    --fg: #ffde07;
    --bg: #def;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--fg);

    @keyframes growProgressBar {
      0%,
      33% {
        --pgPercentage: 0;
      }
      100% {
        --pgPercentage: var(--value);
      }
    }
    // .circle {
    //   width: 50px;
    //   height: 50px;
    //   background-color: red;
    //   border-radius: 50%;
    //   position: relative;
    //   .innerCircle {
    //     width: 40px;
    //     height: 40px;
    //     position: absolute;
    //     background-color: #ffffff;
    //     border-radius: 50%;
    //     top: 5px;
    //     left: 5px;
    //   }
    //   .left-progress {
    //     width: 50px;
    //     height: 50px;
    //     position: absolute;
    //     background-color: rgb(229, 255, 80);
    //     border-radius: 50%;
    //     &.onePercent {
    //       clip: rect(0px 28px 15px 0px);
    //     }
    //     &.twoPercent {
    //       clip: rect(0px 28px 23px 0px);
    //     }
    //     &.treePercent {
    //       clip: rect(0px 28px 31px 0px);
    //     }
    //     &.fourPercent {
    //       clip: rect(0px 28px 39px 0px);
    //     }
    //     &.fivePercent {
    //       clip: rect(0px 28px 50px 0px);
    //     }
    //   }
    //   .right-progress {
    //     width: 50px;
    //     height: 50px;
    //     position: absolute;
    //     background-color: rgb(229, 255, 80);
    //     border-radius: 50%;
    //     &.onePercent {
    //       clip: rect(35px 50px 50px 25px);
    //     }
    //     &.twoPercent {
    //       clip: rect(27px 50px 50px 25px);
    //     }
    //     &.treePercent {
    //       clip: rect(19px 50px 50px 25px);
    //     }
    //     &.fourPercent {
    //       clip: rect(11px 50px 50px 25px);
    //     }
    //     &.fivePercent {
    //       clip: rect(0px 50px 50px 25px);
    //     }
    //   }
    // }
  }
}
