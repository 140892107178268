@import "../../styles/fira-theme.scss";

.Home {
  height: calc(100% - 56px);
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.UserSection {
  position: relative;
}

.FiraUserWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  &__Avatar {
    width: 32px;
    height: 32px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__Info {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $light-text;
  }
}

.Notifications {
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .FiraUserWrapper {
    &__Info {
      display: none;
    }
  }
}
