@import "../../styles/fira-theme.scss";

.FiraTopBarWrapper {
  width: 100%;
  margin: 0;
  height: 56px;
  background-color: $dark-background;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;

  &__LeadingSection {
    display: flex;
    height: 100%;
    align-items: center;
    &__Hamburger {
      display: none;
    }
  }
  &__TrailingSection {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .FiraTopBarWrapper {
    padding-right: 12px;
    padding-left: 5px;
  }

  .FiraTopBarWrapper__LeadingSection__Logo {
    display: none;
  }

  .FiraTopBarWrapper__LeadingSection__Hamburger {
    display: block;
  }

  .FiraUserWrapper__Info {
    display: none;
  }
}
