@import "../../../styles/fira-theme.scss";

.CreateViewWrapper {
  width: 96%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 24px;
  gap: 40px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .emailSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .itemInput {
      width: 100%;
      height: 94px;
      max-width: 50%;
    }
  }

  .roleSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .optionsRole {
      display: flex;
      flex-direction: row;
      gap: 40px;

      .disabled {
        background: $dark-gray-color;
        opacity: 1;
        cursor: not-allowed;
      }
      .enable {
        cursor: pointer;
      }

      .active {
        border: 2px solid $primary-color;
      }
    }
  }

  .containerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
.errorMessage {
  color: #f16063;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
}
