@import "../../../styles/fira-theme.scss";

.CreateViewWrapper {
  width: 100%;
  height: 751px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  padding-top: 24px;
  padding-bottom: 24px;

  .mainContainer {
    width: 95%;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .containerForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 24px;
    margin-top: 24px;

    .itemInput {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .itemText {
    width: 100%;
    display: flex;
    align-content: flex-start;
  }

  .itemTextTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .containerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 4px;
  }
}

.phoneNumberArea {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  height: 40px;
  gap: 8px;
  min-width: 230px;

  .itemCode {
    grid-column: 1 / span 1;
  }

  .itemNumber {
    grid-column: 2 / span 3;
  }
}

.errorMessage {
  color: #f16063;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
}
