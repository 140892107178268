@import "../../styles/fira-theme.scss";

.itemBadge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.badgeLive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  color: white;
  width: 40px;
  height: 14px;
  background: #ff2c30;
  border-radius: 4px;
}

.selectTd {
  display: flex;
  justify-content: center;
}
.buttonTd {
  display: flex;
  justify-content: end;
}
.labelDetail {
  text-decoration: underline;
  cursor: pointer;
}

.emptyInfiniteTableWrapper {
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infiniteTableWrapper {
  height: 75vh;
  margin-bottom: 50px;
}
