@import "../../styles/fira-theme.scss";
.PopUpAlertContainer {
  position: relative;
  padding-bottom: 20px;
  .PopUpALertWrapper {
    width: calc(100% - 40px);
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: $light-background;
    &.warning {
      border: 1px solid $primary-color;
    }
    &.success {
      border: 1px solid $success-color;
    }
    &.error {
      border: 1px solid $danger-color;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
      }
      .closeIcon {
        cursor: pointer;
        background-color: black;
        border-radius: 4px;
        color: white;
        font-size: 16px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-bottom: 16px;
      padding-left: 32px;
      padding-right: 32px;
      .boldText {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .buttonsGroup {
      gap: 8px;
      display: flex;
      flex-direction: row;
      padding-left: 32px;
      padding-right: 32px;
      .button {
        border-radius: 4px;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
        &.border {
          background-color: white;
          &.warning {
            border: 1px solid $primary-color;
          }
          &.success {
            border: 1px solid #babfc3;
          }
          &.error {
            border: 1px solid $danger-color;
          }
        }
        &.fill {
          padding: 9px 16px;
          color: white;
          border: none;
          &.warning {
            color: black;
            background: $primary-color;
          }
          &.success {
            background: $success-color;
          }
          &.error {
            background: $danger-color;
          }
        }
      }
    }
  }
}
