@import "../../styles/fira-theme.scss";

.slideset{
  height: 10em;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color:#F7F8F9;

   >.child{
    position: absolute;
    right: 100%;
    animation: 2s slide infinite ease-in-out;
    overflow: hidden;
    background: linear-gradient(90deg, #F7F8F9 0%, #D5DDE0 50%, #F7F8F9 100%);
    width: 100%;
    height: 100%;
   }
   .one{
    animation-delay: 0.5s
   }
   .two{
    animation-delay: 1.5s
  }
}

@keyframes slide {
  0% {right: 100%}
  37.33% {right: -100%}
  100% {right: -100%}
}