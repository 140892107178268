.ContainerInteractionsWidget {
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 360px;
  width: auto;
  border-radius: 10px;
  padding: 16px 20px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  .TitleWidget {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .SubtitleWidget {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .legendList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .PieChartContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.CustomTooltip {
  background: #ffffff;
  padding: 8px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  .textValue {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .textLabel {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #abafb1;
  }
}

.TextLegend {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

//Loading State
.LoadingStateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &__Top {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
    gap: 4px;
    position: relative;
    div {
      height: 22px;
      border-radius: 6px;
      background-size: 400% 400%;
      background-color: #f7f8f9;
    }
  }

  &__PieContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__PieContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
    animation: 1s slide infinite linear;
    animation-delay: 1.5s;
  }
  &__Bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3%;
    width: 100%;
  }
}

.LoadingItem {
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1.8fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 4px;
  grid-template-areas:
    "first-area second-area"
    ". third-area";

  .FirstArea {
    background-color: #f7f8f9;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    grid-area: first-area;
  }
  .SecondArea {
    background-color: #f7f8f9;
    width: 100%;
    height: 22px;
    border-radius: 6px;
    grid-area: second-area;
  }
  .ThirdArea {
    background-color: #f7f8f9;
    width: 80%;
    height: 22px;
    border-radius: 6px;
    grid-area: third-area;
  }
}

@keyframes slide {
  0% {
    right: 100%;
  }
  50% {
    right: -100%;
  }
  100% {
    right: -100%;
  }
}

//EmptyState
.EmptyStateContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  .Labels {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .PictureContainer {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//Custom Legend

.CustomLegend {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  // flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
  gap: 2px;
  .LegendItem {
    display: flex;
    flex-direction: column;
    .LegendLabel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      align-items: center;
      word-break: break-all;
      .Icon {
        // width: 14px;
        // height: 14px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        flex-shrink: 0;
      }
      span {
        margin-left: 8px;
        white-space: nowrap;
      }
    }
    .LegendValue {
      font-size: 24px;
      font-weight: 600;
      margin-left: 18px;
    }
  }
}

.ColumnFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// @media screen and (max-width: 1300px) {
//   .PieChartContainer {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }
// }

// @media screen and (min-width: 1300px) {
//   .PieChartContainer {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//   }

//   .CustomLegend {
//     flex-direction: column;
//   }
// }
