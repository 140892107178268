@import "../../styles/fira-theme.scss";

.ToggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  .switch {
    position: relative;
    height: 24px;
    width: 56px;
    display: inline-block;
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #d0d0d0;
      border-radius: 25px;
      transition: 0.4s;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background-color: $primary-color;
      left: 4px;
      top: 4px;
      transition: 0.4s;
    }

    input {
      display: none;
    }

    input:checked + .slider {
      background-color: #010202;
    }

    input:disabled + .slider {
      background-color: #d0d0d0;
    }

    input:checked + .slider::before {
      transform: translateX(56px - 25px);
    }
  }
}
