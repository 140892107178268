@import "../../../styles/fira-theme.scss";

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.LinkToNewClient {
  color: $main-text;
  text-decoration: none;
}

.infiniteTableWrapper {
  height: 75vh;
  margin-bottom: 50px;
}

.selectVisibility {
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  width: 100%;
  height: stretch;
  background-color: $light-background;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $dark-gray-color;
  height: 100%;
  * {
    font-size: 400px;
  }
}
.itemLive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.badgeLive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  gap: 4px;
  color: white;
  width: 40px;
  height: 14px;

  background: #ff2c30;
  border-radius: 4px;
}

.selectTd {
  display: flex;
  justify-content: center;
}
.buttonTd {
  display: flex;
  justify-content: flex-end;
}
.labelDetail {
  text-decoration: underline;
  cursor: pointer;
}

.itemStatus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.badgeStatus {
  display: flex;

  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  width: 80px;

  &.active {
    border-radius: 4px;
    background: #fff29c;
  }
  &.inactive {
    border-radius: 4px;
    background: #f7f8f9;
  }
}
