.GreetingsWrapper {
  min-height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .TextWrapper {
    display: flex;
    flex-direction: column;
    .Title {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
    }
    .Info {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
